<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="700px" top="10vh" :append-to-body="true"
        :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm"
                    label-width="120px">
                   
                    <el-form-item label="标题：" prop="title">
                        <el-input v-model="m.title"></el-input>
                    </el-form-item>
                
                    <el-form-item label="预期价格：" prop="totalPrice">
                        <el-input v-model="m.totalPrice"></el-input>
                    </el-form-item>
                    <el-form-item label="预计完成时间：" prop="planDate">
                        <el-date-picker v-model="m.planDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="预计完成时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="使用场景">
                        <input-enum enumName="purchaseTypeEnum" v-model="m.type"></input-enum>
                    </el-form-item>
                    <el-form-item label="采购员：" prop="receiver">
                        <el-input v-model="m.receiver"></el-input>
                    </el-form-item>
                    <el-form-item label="收货电话：" prop="mobile">
                        <el-input v-model="m.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="收货地址">
                        <el-input type="textarea" rows="2" placeholder="收货地址" v-model="m.address"></el-input>
                    </el-form-item>
                   
            
                    <el-form-item label="采购需求描述">
                        <el-input type="textarea" rows="2" placeholder="采购需求描述" v-model="m.info"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <input-enum enumName="purchaseStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                projectId: [],
                projectCode: [],
                title: [],
                code: [],
                totalPrice: [{ pattern: /^\d{0,}$/, message: '预期价格必须为数字类型', trigger: 'blur' },],
                planDate: [],
                type: [],
                receiver: [],
                mobile: [],
                address: [],
                num: [{ pattern: /^\d{0,}$/, message: '总数量必须为数字类型', trigger: 'blur' },],
                finishDate: [],
                info: [],
                state: [],
            },
            fileList: [],
        }
    },
    methods: {
        open: function (data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改 采购单";
                this.m = data;
            } else {
                this.m = {
                    projectId: data.projectId,
                    projectCode: '',
                    title: '',
                    code: '',
                    totalPrice: 0,
                    planDate: '',
                    type: '',
                    receiver: '',
                    mobile: '',
                    address: '',
                    actualPrice: 0,
                    num: 0,
                    finishDate: '',
                    info: '',
                    state: 0
                }
                this.title = "添加 采购单";
            }
        },


        //提交采购单信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {

                    this.sa.post("/purchase/save", this.m).then((res) => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {
    },
};
</script>